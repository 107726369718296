@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Roobert';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/RoobertRegular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Roobert';
    font-style: normal;
    font-weight: 500;
    src: url('./fonts/RoobertMedium.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Roobert';
    font-style: normal;
    font-weight: 300;
    src: url('./fonts/RoobertLight.ttf') format('truetype');
  }
}

body {
  background-color: black;
  font-family: 'Roobert', sans-serif;
}

.terms-list {
  list-style: none;
  padding-left: 0;
  counter-reset: section;
}

.terms-list > li {
  counter-increment: section;
}

.terms-list > li::before {
  content: counters(section, '.') '. ';
}

.terms-list ol {
  list-style: none;
  counter-reset: sub-section;
}

.terms-list ol > li {
  counter-increment: sub-section;
  margin-top: 16px;
}

.terms-list ol > li::before {
  content: counters(section, '.') '.' counters(sub-section, '.') ' ';
}

.terms-list ol ol {
  list-style: none;
  padding-left: 20px;
  counter-reset: sub-sub-section;
}

.terms-list ol ol > li {
  counter-increment: sub-sub-section;
}

.terms-list ol ol > li::before {
  content: counters(section, '.') '.' counters(sub-section, '.') '.'
    counters(sub-sub-section, '.') ' ';
}

/* custom scrollbar */

.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #222;
  border-radius: 8px;
  margin-top: 8px;
  margin-bottom: 32px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #91dbbf;
  border-radius: 8px;
}
